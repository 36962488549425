<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
      :disabled="user.isAdmin"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="user.name"
                  dense
                  :label="$t('name_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="user.email"
                  dense
                  :label="$t('email_*')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>

              <v-col
                v-if="authUser.isAdmin"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="user.user_type"
                  dense
                  disabled
                  :items="userTypeList"
                  item-value="id"
                  item-text="name"
                  :label="$t('user_type_*')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                v-if="
                  !isSystemUserType &&
                    authUser.isAdmin &&
                    user.user_type == 'oem'
                "
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="user.client_id"
                  dense
                  disabled
                  :items="clientsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="!isSystemUserType ? [rules.required] : null"
                />
              </v-col>

              <v-col
                v-if="
                  !isSystemUserType &&
                    authUser.isAdmin &&
                    user.user_type == 'tenant'
                "
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="user.tenant_id"
                  dense
                  disabled
                  :items="clientsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="!isSystemUserType ? [rules.required] : null"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="user.password"
                  dense
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="false"
                  :label="$t('password_*')"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="user.role"
                  dense
                  clearable
                  :items="listRoles"
                  item-text="name"
                  item-value="name"
                  :label="$t('role_*')"
                  :rules="[rules.multi_select_required]"
                  multiple
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="user.is_active"
                  dense
                  :items="statusLists"
                  item-value="id"
                  item-text="name"
                  :label="$t('status_*')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col
                v-if="isUserRequiredLocation"
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="user.location_id"
                  dense
                  :disabled="!isUserRequiredLocation"
                  :items="locationsList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_location_*')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggleEdit()"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            :disabled="user.isAdmin"
            @click="updateItem(user)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    user: {
      type: Object,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      showPassword: false,
      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      userTypeList: [
        {
          id: "orko",
          name: "ORKO",
        },
        {
          id: "tenant",
          name: "Tenant",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      listRoles: "roles/listRoles",
      clientsList: "meta/clientsList",
      locationsList: "locations/locationsList",
      authUser: "getUser",
    }),

    isSystemUserType() {
      return this.user.user_type == "orko";
    },
    isUserRequiredLocation() {
      return this.user.role
        ? this.user.role.includes("Cpo-Manager") ||
            this.user.role.includes("Cpo-Staff")
        : false;
    },
  },
  watch: {
    "user.user_type": {
      handler: async function (value) {
        await this.$store.dispatch("meta/getClients", value);
        if (value == "orko") {
          this.$store.dispatch("roles/list", { type: value });
        }
      },
    },
    "user.role": {
      handler: async function () {
        if (this.isUserRequiredLocation) {
          await this.$store.dispatch("locations/list", {
            cpo_client_id: this.user.client_id,
          });
        }
      },
    },
    "user.client_id": {
      handler: async function () {
        await this.$store.dispatch("roles/list", {
          tenant_id: this.user.client_id,
        });
        if (this.isUserRequiredLocation) {
          await this.$store.dispatch("locations/list", {
            cpo_client_id: this.user.client_id,
          });
        }
      },
    },
  },
  mounted() {
    if (this.authUser.isAdmin) {
      this.preSetData();
    }
  },

  methods: {
    async updateItem(user) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        await this.$store
          .dispatch("users/update", user)
          .then(async () => {
            this.$store.dispatch("users/list", this.filterOptions);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
            this.isLoadingSave = false;
            this.toggleEdit();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
    async preSetData() {
      let value = this.user.user_type;
      await this.$store.dispatch("meta/getClients", value);
      if (value == "orko") {
        this.$store.dispatch("roles/list", { type: value });
      } else if (value == "oem") {
        await this.$store.dispatch("roles/list", {
          oem_client_id: this.user.client_id,
        });
      } else if (value == "tenant") {
        await this.$store.dispatch("roles/list", {
          tenant_id: this.user.tenant_id,
        });
      }
    },
  },
};
</script>
